<script setup lang="ts">
const { onAppMounted } = useNavigation()
const { clientTokenCookie } = useUser()
const { navigateToHome } = useNavigation()

useHead({
  title: "Poly: The Intelligent Cloud Image Browser | Join the Waitlist",
  bodyAttrs: {
    class: "text-$global-t-default text-base bg-bg-secondary",
  },
})

onMounted(async () => {
  // TODO: should we always redirect?
  if (clientTokenCookie.value)
    return await navigateToHome()

  // initiate our workers just for speed's sake
  onAppMounted()
})
</script>

<template>
  <nuxt-page />
</template>
